<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('') }}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="sendReadyContainersToFlight(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="24">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div
                            class="
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div class="mb-4 cargo__right">
                                <select-from-filial 
                                    v-if="!scan"
                                    v-model="form.from_filial_id" 
                                    :id="form.from_filial_id" 
                                    :select_only="true"
                                    :placeholder="$t('message.from_where')" 
                                    class="select__width w-100 mr-5" 
                                />
                                <select-to-filial 
                                    v-if="!scan"
                                    v-model="form.to_filial_id" 
                                    :id="form.to_filial_id" 
                                    :select_only="true"
                                    :placeholder="$t('message.to_where')" 
                                    class="select__width w-100" 
                                />
                                <el-input v-if="scan" class="mr-5" ref="scanRef" 
                                    @input="scanContainerBarcodeDebounce" 
                                    :placeholder="$t('message.scan')" 
                                    v-model="barcode_search" 
                                    size="mini">
                                </el-input>
                                <el-switch
                                    v-model="scan"
                                    class="p-3"
                                    :active-text="$t('message.scan')"
                                    :inactive-text="$t('message.select')">
                                </el-switch>
                            </div>
                            <div class="" v-loading="loadingData">
                                <template>
                                    <el-table
                                        :data="list"
                                        stripe
                                        border
                                        style="width: 100%"
                                        @selection-change="handleSelectionChange">
                                        <el-table-column
                                            type="index"
                                            width="50">
                                        </el-table-column>
                                        <el-table-column v-if="!scan"
                                            type="selection"
                                            width="55">
                                        </el-table-column>
                                        <el-table-column
                                            prop="id"
                                            :label="$t('message.n')"
                                            width="80">
                                        </el-table-column>

                                        <el-table-column
                                            prop="name"
                                            :label="$t('message.name')"
                                            >
                                            <template slot-scope="scope">
                                                {{ scope.row.containerType }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="barcode"
                                            :label="$t('message.barcode')"
                                            >
                                        </el-table-column>

                                        <el-table-column
                                            prop="from_where"
                                            :label="$t('message.from_where')"
                                            >
                                            <template slot-scope="scope">
                                                {{ scope.row.from_filial ? scope.row.from_filial.name : '' }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="to_where"
                                            :label="$t('message.to_where')"
                                            >
                                            <template slot-scope="scope">
                                                {{ scope.row.to_filial ? scope.row.to_filial.name : '' }}
                                            </template>
                                        </el-table-column>
                                        
                                        <el-table-column
                                            prop="deals"
                                            :label="$t('message.deals')"
                                            width="180">
                                            <template slot-scope="scope">
                                                <span v-for="(deal, key) in scope.row.deals" :key="'deal'+deal"> {{ deal }} <span v-if="key+1 != scope.row.deals.length"> , </span> </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="total_weight"
                                            width="180"
                                            :label="$t('message.weight_in_kg')">
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
</div>
</template>

<script>

import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { mapGetters, mapActions } from "vuex";
import selectToFilial from "@/components/selects/select-from-filial";
import selectFromFilial from "@/components/selects/select-to-filial";

export default {
    mixins: [form, drawer],
    components: {
        selectFromFilial,
        selectToFilial
    },
    data: () => ({
        mode: true,
        loadingData: false,
        list: [],
        scan: false,
        barcode_search: '',
        selectedContainers: [],
    }),
    watch: {
        scan: {
            handler: async function(newVal, oldVal) {
                if(!newVal){
                    this.fetchData();
                }
            },
            deep: true,
            immediate: true
        },
        watchFilials: {
            handler: async function(newVal, oldVal) {
                this.list = [];
                if(newVal && this.form.from_filial_id && this.form.to_filial_id){
                    this.fetchData();
                }
            },
            deep: true,
            immediate: true
        },
    },

    computed: {
        ...mapGetters({
            authUser: "auth/user",
            rules: "containers/rules",
            model: "containers/model",
            columns: "dealProducts/columns",
            readyToFlight: "containers/readyToFlight",
        }),
        watchFilials(){
            return this.form.from_filial_id + this.form.to_filial_id + 'aaa';
        }
    },
    methods: {
        ...mapActions({
            save: "containers/sendReadyContainersToFlight",
            readyToSendToFlight: "containers/readyToSendToFlight",
            scanContainer: "containers/scanContainer",
        }),
        handleSelectionChange(val) {
            this.selectedContainers = val;
        },
        afterOpen(){
            this.fetchData();
        },  
        afterLeave(){
            this.list = [];
        },  
        fetchData(){
            if(this.$refs['scanRef']){
                this.$refs['scanRef'].focus(); 
            } 
            if(!this.scan && this.form.from_filial_id){
                this.loadingData = true;
                this.readyToSendToFlight({sendable: true, from_filial_id: this.form.from_filial_id, to_filial_id: this.form.to_filial_id}).then(res => {
                    this.list = JSON.parse(JSON.stringify(this.readyToFlight));
                    this.loadingData = false;
                }).catch(err => {this.loadingData = false;});
            }
        },
        stringContainString(arr) {
            return arr[1].split('').every(function(letter) {
                return arr[0].indexOf(letter) != -1;
            });
        },
        scanContainerBarcodeDebounce: _.debounce(
            function(val) {
            this.scanContainerBarcode(val);
            }, 300),
        scanContainerBarcode(val){
            let upper_barcode = val.toUpperCase();
            if(upper_barcode && this.stringContainString([upper_barcode, 'C-F'])){
                this.scanContainer({search: upper_barcode, sendable: true})
                    .then(res => {
                        if(res.data.result.success){
                            let container = JSON.parse(JSON.stringify(res.data.result.data))
                            if(this.list.filter(el => el.id === container.id).length === 0){
                                this.list.push(container);
                            }else{
                                this.$notify({
                                    title: this.$t('message.container'),
                                    type: "error",
                                    offset: 130,
                                    message: this.$t('message.container_already_exists')
                                });
                            }
                            this.barcode_search = '';
                        }else{
                            this.$error_message(res);
                            this.barcode_search = '';
                        }
                    }).catch(err => {
                    });

            }
        },

        sendReadyContainersToFlight(){
            let selected = [];
            if(this.scan){
                selected = this.list.map(el => el.id);
            }else{
                selected = this.selectedContainers.map(el => el.id);
            }
            if(selected.length > 0){
                this.loadingButton = true;
                this.save({containers: selected})
                .then((res) => {
                    this.loadingButton = false;
                    this.$alert(res);
                    this.parent().listChanged();
                    if (close) this.close();
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$alert(err);
                });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.no_selected_containers'),
                });
            }
        }
    }


}
</script>

<style>

</style>
 